<template>
  <div>
    <van-popup v-model="show" @closed="closed" position="bottom" round
      :style="{ minHeight: '35%', paddingTop: '1rem' }">
      <div class="topTitle">
        <div class="seizeseat"></div>
        <div class="centertitle">支付方式</div>
        <div @click='closed'> <van-icon size="1.2rem" name="cross" color="#666" /></div>

      </div>
      <div v-for="(item, index) in payList" class="payitem">
        <div class="lifeText"> {{ item.showName }}</div>
        <div class="rightText"> {{ item.showPrice }}</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "",
  props: {

    payList: {
      type: Array,
    },
  },
  data() {
    return {
      show: true,

    };
  },
  components: {},
  methods: {
    closed() {
      this.$emit("closed", false);
    },
  },
  mounted() {
  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.topTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1.1rem;
  margin-top: 0.28rem;
  margin-bottom: 1rem;

  .seizeseat {
    width: 1.2rem;
  }

  .centertitle {
    font-size: 1.16rem;
  }

}

.payitem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  min-height: 2rem;
  padding: 0 1rem;

  .lifeText {
    flex: 1;
  }

  .rightText {
    color: red;
    min-width: 4rem;
    text-align: end;
  }
}
</style>